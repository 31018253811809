<script>
import RatesList from "@/views/pages/rates/components/List.vue";
import PageHeader from "@/components/page-header.vue";

export default {
  name: "ratesIndex",
  components: {
    RatesList,
    PageHeader
  },
  data() {
    return {
      items: [
        {
          text: "Rates",
        },
        {
          text: "List",
          active: true,
        },
      ],
    }
  },

}
</script>

<template>
  <PageHeader title="Rates" :items="items"/>

  <RatesList/>
</template>

<style scoped>

</style>