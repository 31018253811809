<script>
import Table from "@/components/tables/table.vue";
import ViewText from "@/views/pages/inquires/modals/ViewText.vue";
import moment from "moment";

export default {
  name: "ratesList",
  components: {
    Table,
    ViewText
  },
  data() {
    return {
      headers: [
        {
          label: 'Departure',
          field: 'departure',
          align: 'center',
        },
        {
          label: 'Destination',
          field: 'destination',
          align: 'center',
        },
        {
          label: 'Product',
          field: 'product',
          align: 'center',
        },
        {
          label: 'Order Number',
          field: 'order',
          align: 'center',
        },
        {
          label: 'Container Type',
          field: 'container_type',
          align: 'center',
          searchType: 'select',
          searchOptions: [
            {value: '', label: 'All'},
            {value: '20', label: '20'},
            {value: '20HC', label: '20HC'},
            {value: '40', label: '40'},
            {value: '40HC', label: '40HC'},
            {value: '45', label: '45'},
          ],
        },
        {
          label: 'Agreed Rate',
          field: 'agreed_rate',
          align: 'center',
        },
        {
          label: 'Company',
          field: 'company',
          align: 'center',
        },
        {
          label: 'Inquiry Message',
          field: 'inquiry',
          align: 'center',
          maxWidth: '400px',
        },
        {
          label: 'Date',
          field: 'date',
          align: 'center',
        },
      ],
      order_types: {
        container_order: {
          path_name: 'orders_container_detail',
          label: 'Container',
        },
        wagon_order: {
          path_name: 'orders_wagon_detail',
          label: 'Wagon',
        },
        wagon_empty_order: {
          path_name: 'orders_empty_wagon_detail',
          label: 'Empty Wagon ',
        },
      }
    }
  },
  methods: {
    cleanText(html) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText;
    },
    openViewTextModal(data) {
      this.$refs.viewText.openModal(data);
    },
    formatDateTime(value) {
      if (!value) return '';
      return moment(value).format('LLL');
    },
  }
}
</script>

<template>
  <ViewText ref="viewText"/>

  <Table
      url="/order/order_rates/"
      :headers="headers"
      name="Rates list"
      :searchable="true"
  >
    <template v-slot:inquiry="{row:data}">
      <div class="d-flex gap-2 justify-content-center align-items-center" v-if="data.inquiry">
        <div @click="openViewTextModal(data.inquiry)"
             class="text-truncate d-flex align-items-center gap-1 cursor-pointer">
          <p class="mb-0 text-truncate">
            {{ cleanText(data.inquiry.text) }}
          </p>
          <i class="mdi mdi-eye"></i>
        </div>
        <router-link :to="{
          name: 'Inquires',
          query: {
            sender: data.inquiry.sender.username,
            order: data.order.number,
            status: data.inquiry.status,
          }
        }">
          <i class="bx bx-link-external align-middle text-primary fw-medium"></i>
        </router-link>
      </div>
    </template>

    <template v-slot:company="{row: data}">
      <router-link v-if="data.company && data.company.slug" :to="{
        name: 'customer_profile_main',
        params: {
          slug: data.company.slug
        }
      }">
        {{ data.company.name }}
      </router-link>
    </template>
    <template v-slot:order="{row:data}">
      <div v-if="data.order" class="d-flex flex-column justify-content-center gap-2">
        <div>
          <span class="badge badge-soft-secondary fs-12">
        <router-link :to="{
          name: order_types[data.order.type].path_name,
           params: {id: data.order.number
          }}">
          {{ data.order.number }}
        </router-link>
      </span>
        </div>
        <small class="text-muted">
          {{ order_types[data.order.type] ? order_types[data.order.type].label : data.order.type }}
        </small>
      </div>
    </template>
    <template v-slot:departure="{row:data}">
      {{
        data.departure
            ? data.departure.name + ' (' + data.departure.code + ')'
            : '-'
      }}
    </template>
    <template v-slot:destination="{row:data}">
      {{
        data.destination
            ? data.destination.name + ' (' + data.destination.code + ')'
            : '-'
      }}
    </template>
    <template v-slot:product="{row:data}">
      {{
        data.product
            ? data.product.name + ' (' + data.product.hc_code + ')'
            : '-'
      }}
    </template>
    <template v-slot:agreed_rate="{row:data}">
      $ {{
        parseFloat(data.agreed_rate || 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      }}
    </template>
    <template v-slot:date="{row:data}">
      {{
        formatDateTime(data.date)
      }}
    </template>
  </Table>
</template>

<style scoped>

</style>